<template>
  <ApLoading
    v-if="isLoading"
    class="mt-10"
    text="Loading Match Criteria Rule..."
  />

  <RulesStatusError v-else-if="isError" @click="getMatchCriteriaRule" />

  <div v-else class="pa-4 mx-auto">
    <MatchingCriteriaConfidenceScore @saved="$emit('saved')" />
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './RulesStatusError'
import MatchingCriteriaConfidenceScore from './MatchingCriteriaConfidenceScore'
import { mapActions, mapState } from 'vuex'
import { RULE_TYPES } from '@/api/business-rules/rules'

export default {
  components: {
    ApLoading,
    RulesStatusError,
    MatchingCriteriaConfidenceScore,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
    }
  },
  computed: {
    ...mapState('business-rules', {
      directorRule: (state) => state.directorRule,
      rule: (state) => state.directorRule.rules.MatchCriteria,
    }),
  },
  created() {
    this.getMatchCriteriaRule()
  },
  methods: {
    ...mapActions('business-rules', ['getRule', 'assignLocalRuleToDirector']),
    async getMatchCriteriaRule() {
      this.isLoading = true
      this.isError = false

      try {
        if (this.directorRule.rules?.MatchCriteria?.id) {
          await this.getRule(this.directorRule.rules.MatchCriteria.id)
        } else {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: Matching Rule`,
            description: 'Manually created Matching rule',
            entities: 'Account',
            type: RULE_TYPES.matchCriteria,
            rule: {
              class: 'OrClass',
              config: [],
            },
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
