<template>
  <v-card class="py-3">
    <v-card-title class="pa-6 mb-2">
      <div class="font-weight-black text-h6 mx-auto">
        Boost Match Confidence Score
      </div>
      <v-icon
        v-ripple
        class="ap-light-grey d-flex align-center pa-1 rounded-sm cursor-pointer close-btn"
        size="16"
        @click="$emit('close')"
      >
        $mdi-close
      </v-icon>
    </v-card-title>

    <div class="d-flex flex-column pa-6 pb-5 pt-0">
      <MatchingCriteriaConfidenceScoreCriteria
        class="mb-5"
        :conditions="criteria.conditions"
        :index="index"
      />

      <div>
        <div class="mb-1">
          If there is also a match on the folowing field(s),
        </div>

        <v-text-field
          class="mb-5"
          append-icon="$mdi-cog"
          outlined
          dense
          hide-details
        />
      </div>

      <div class="mb-6">
        <div class="mb-1">Boost the Match Confidence Score to</div>

        <v-select
          :items="items"
          outlined
          dense
          hide-details
          :menu-props="{ offsetY: true }"
        />
      </div>

      <v-btn class="mx-auto" color="primary" @click="$emit('close')">
        save
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import MatchingCriteriaConfidenceScoreCriteria from './MatchingCriteriaConfidenceScoreCriteria'

export default {
  components: {
    MatchingCriteriaConfidenceScoreCriteria,
  },
  props: {
    criteria: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    menu2: null,
    score: null,
    items: ['High', 'Medium', 'Low'],
  }),
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
