<template>
  <div
    class="rounded px-3 py-1 text-caption d-flex align-center white border-style"
  >
    <div v-if="order" class="circle-24 ap-light-grey mr-4">
      {{ order }}
    </div>

    <div class="my-1 mr-auto">
      <template v-for="(condition, conditionIndex) in conditionsByAlgo">
        <div v-if="condition.fields.length > 0" :key="conditionIndex">
          {{ condition.label }} on
          <span
            v-for="(field, fieldIndex) in condition.fields"
            :key="field.field"
          >
            <b>{{ field.field }}</b>
            <span v-if="fieldIndex < condition.fields.length - 2">, </span>
            <span v-else-if="fieldIndex < condition.fields.length - 1">
              and
            </span>
          </span>
        </div>
      </template>
    </div>

    <v-btn
      v-if="isBoostModalVisible"
      icon
      color="black"
      @click="$emit('boost')"
    >
      <v-icon size="18"> $mdi-rocket-launch </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    conditions: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isBoostModalVisible: false,
  }),
  computed: {
    conditionsByAlgo() {
      return [
        {
          label: 'Exact Match',
          fields: this.conditions.filter((el) => el.algo === 'exact'),
        },
        {
          label: 'Starts With',
          fields: this.conditions.filter((el) => el.algo === 'startswith'),
        },
        {
          label: 'Fuzzy Match',
          fields: this.conditions.filter((el) => el.algo === 'fuzzy'),
        },
      ]
    },
  },
}
</script>

<style scoped>
.border-style {
  border: 1px solid var(--v-ap-grey-base) !important;
}

.circle-24 {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
