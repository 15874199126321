<template>
  <div class="drop-area pa-3 position-relative" style="min-height: 50px">
    <div
      v-if="isPlaceholderVisible"
      class="text-body-2 d-flex align-center justify-center ap-dark-gray--text"
      style="position: absolute; top: 0; bottom: 0; left: 0; right: 0"
    >
      {{ placeholder }}
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    isPlaceholderVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.drop-area {
  border: 2px dashed var(--v-ap-grey-base);
}
</style>
