<template>
  <div class="d-flex align-center mb-4">
    <v-icon :color="color">
      {{ icon }}
    </v-icon>

    <span class="text-body-2 font-weight-medium mx-2" :class="`${color}--text`">
      {{ title }} ({{ total }})
    </span>

    <div class="flex-grow-1" :class="color" style="height: 4px" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
}
</script>
