<template>
  <div>
    <MatchingCriteriaConfidenceScoreTitle
      :title="name"
      :color="color"
      :icon="icon"
      :total="criterias.length"
    />

    <DropArea
      placeholder="Drag & Drop Match Criteria here"
      :is-placeholder-visible="criterias.length === 0"
    >
      <Draggable
        class="list-group"
        :list="criterias"
        group="criterias"
        @change="$emit('change', criterias)"
      >
        <MatchingCriteriaConfidenceScoreCriteria
          v-for="(criteria, index) in criterias"
          :key="criteria.index"
          :order="criteria.index + 1"
          :conditions="criteria.conditions"
          class="list-group-item"
          @boost="$emit('boost', { criteria, index })"
        />
      </Draggable>
    </DropArea>
  </div>
</template>

<script>
import DropArea from './DropArea.vue'
import Draggable from 'vuedraggable'
import MatchingCriteriaConfidenceScoreTitle from './MatchingCriteriaConfidenceScoreTitle'
import MatchingCriteriaConfidenceScoreCriteria from './MatchingCriteriaConfidenceScoreCriteria'

export default {
  components: {
    DropArea,
    Draggable,
    MatchingCriteriaConfidenceScoreTitle,
    MatchingCriteriaConfidenceScoreCriteria,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    initialList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dragOptions: {
      group: 'criteria',
      animation: 200,
    },
    criterias: [],
    mergeReviewBy: [],
  }),
  created() {
    this.criterias = [...this.initialList]
  },
}
</script>

<style lang="scss" scoped>
.drop-area {
  border: 2px dashed var(--v-ap-grey-base);
}
.field-wrapper {
  height: 100%;
  width: 100%;

  .v-select {
    width: 188px;
  }
}

.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: grab;
}
.list-group-item:not(:last-child) {
  margin-bottom: 4px;
}
</style>
