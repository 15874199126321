<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading Match Criterias..." />
  <div v-else>
    <div class="mx-auto" style="max-width: 1000px">
      <div class="text-h5 font-weight-black mb-6">
        What action do you want to take on the matches?
      </div>
    </div>

    <div class="d-flex justify-center">
      <div class="pr-8" style="width: 400px">
        <v-alert text dense :type="mcsAlertType" class="mb-1">
          <div v-if="isAllMatchCriteriaAssignedToALevel" class="text-caption">
            All Match Criterias assigned to a Confidence level.
          </div>
          <div v-else class="text-caption">
            Organize your match criteria based on confidence that they will be
            duplicated. Drag and drop each of the criteria under the respective
            confidence level.
          </div>
        </v-alert>

        <Draggable
          v-bind="dragOptions"
          class="list-group"
          :list="matchingCriterias"
          group="criterias"
        >
          <MatchingCriteriaConfidenceScoreCriteria
            v-for="criteria in matchingCriterias"
            :key="criteria.index"
            :order="criteria.index + 1"
            :conditions="criteria.conditions"
            class="list-group-item"
            @boost="openBoostDialog({ criteria, index: i })"
          />
        </Draggable>
      </div>

      <div style="width: 600px">
        <MatchingCriteriaConfidenceScoreCard
          v-for="level in levels"
          :key="level.prop"
          :name="level.name"
          :icon="level.icon"
          :color="level.color"
          :initial-list="getInitialList(level.prop)"
          class="mb-3"
          @change="onCriteriasLevelChanged(level.prop, $event)"
        />
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="540">
      <MatchingCriteriaConfidenceScoreBoostMatch
        :criteria="activeCriteria"
        :index="activeIndex"
        @close="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import MatchingCriteriaConfidenceScoreCard from './MatchingCriteriaConfidenceScoreCard'
import MatchingCriteriaConfidenceScoreBoostMatch from './MatchingCriteriaConfidenceScoreBoostMatch'
import MatchingCriteriaConfidenceScoreCriteria from './MatchingCriteriaConfidenceScoreCriteria'
import Draggable from 'vuedraggable'
import ApLoading from '@/components/common/ApLoading'
import { mapState, mapActions } from 'vuex'
import { cloneDeep, isEqual } from 'lodash-es'

export default {
  components: {
    MatchingCriteriaConfidenceScoreCard,
    MatchingCriteriaConfidenceScoreBoostMatch,
    Draggable,
    ApLoading,
    MatchingCriteriaConfidenceScoreCriteria,
  },
  data: () => ({
    levels: [
      {
        prop: 'highScoreCriterias',
        name: 'High Score',
        icon: '$mdi-alpha-hcircle',
        color: 'ap-green',
      },
      {
        prop: 'mediumScoreCriterias',
        name: 'Medium Score',
        icon: '$mdi-alpha-mcircle',
        color: 'ap-yellow',
      },
      {
        prop: 'lowScoreCriterias',
        name: 'Low Score',
        icon: '$mdi-alpha-lcircle',
        color: 'ap-orange',
      },
    ],
    isLoading: false,
    isError: false,
    dialog: null,
    dragOptions: {
      group: 'criteria',
      animation: 200,
      sort: false,
    },
    lowScoreCriterias: [],
    mediumScoreCriterias: [],
    highScoreCriterias: [],
    activeCriteria: null,
    activeIndex: null,
    matchingCriterias: [],
    originalMatchingCriterias: [],
  }),
  computed: {
    ...mapState('business-rules', {
      directorRule: (state) => state.directorRule,
      matchCriteriaRule: (state) => state.directorRule.rules.MatchCriteria,
      isTriggeringSave: (state) =>
        state.directorRule.rules.MatchCriteria.isTriggeringSave,
    }),
    isAllMatchCriteriaAssignedToALevel() {
      return (
        Object.keys(this.matchCriteriaRule).length > 1 &&
        this.matchingCriterias.length === 0
      )
    },
    mcsAlertType() {
      if (this.isAllMatchCriteriaAssignedToALevel) {
        return 'success'
      }

      if (this.isError) {
        return 'error'
      }

      return 'info'
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        try {
          if (this.matchingCriterias.length > 0) {
            this.isError = true
            this.resetTriggerSaveCurrentRule('MatchCriteria')
          } else {
            const criteriasWithConfidenceScore = [
              ...this.highScoreCriterias.map((el) => {
                return { ...el, confidence: 1 }
              }),
              ...this.mediumScoreCriterias.map((el) => {
                return { ...el, confidence: 0.7 }
              }),
              ...this.lowScoreCriterias.map((el) => {
                return { ...el, confidence: 0.3 }
              }),
            ]
              .sort((a, b) => a.index - b.index)
              .map((el) => {
                delete el.index

                return el
              })

            if (
              !isEqual(
                this.originalMatchingCriterias,
                criteriasWithConfidenceScore
              )
            ) {
              await this.updateMatchCriteriaRule({
                rule: this.matchCriteriaRule,
                criterias: criteriasWithConfidenceScore,
              })
            }

            this.$emit('saved')
          }
        } finally {
          this.resetTriggerSaveCurrentRule('MatchCriteria')
        }
      }
    },
  },
  async created() {
    if (
      !this.matchCriteriaRule ||
      Object.keys(this.matchCriteriaRule).length < 2
    ) {
      this.isLoading = true
      await this.getRule(this.directorRule.rules.MatchCriteria.id)
      this.isLoading = false
    }

    const matchingCriterias = this.matchCriteriaRule.rule.config.map(
      (item) => item.config
    )

    this.originalMatchingCriterias = cloneDeep(matchingCriterias)

    const matchingCriteriasWithIndex = matchingCriterias.map((item, index) => ({
      ...item,
      index,
    }))

    this.matchingCriterias = matchingCriteriasWithIndex.filter(
      (el) => el.confidence === undefined || el.confidence === null
    )

    const matchingCriteriasWithScore = matchingCriteriasWithIndex.filter(
      (el) => el.confidence !== undefined && el.confidence !== null
    )
    this.lowScoreCriterias = matchingCriteriasWithScore.filter(
      (el) => el.confidence >= 0 && el.confidence < 0.7
    )
    this.mediumScoreCriterias = matchingCriteriasWithScore.filter(
      (el) => el.confidence >= 0.7 && el.confidence < 1
    )
    this.highScoreCriterias = matchingCriteriasWithScore.filter(
      (el) => el.confidence == 1
    )
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'updateMatchCriteriaRule',
      'resetTriggerSaveCurrentRule',
    ]),
    getInitialList(code) {
      return this[code]
    },
    onCriteriasLevelChanged(code, criterias) {
      this[code] = criterias
    },
    openBoostDialog(payload = null) {
      if (payload) {
        this.activeCriteria = payload.criteria
        this.activeIndex = payload.index
      }
      this.dialog = true
    },
  },
}
</script>

<style scoped>
.list-group-item {
  cursor: grab;
}

.list-group-item:not(:last-child) {
  margin-bottom: 4px;
}
</style>
